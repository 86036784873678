<template>
    <div class="dynamic_main scrollbar-class">
        <div class="dynamic_header">
            <div class="title">监测动态</div>
            <div class="tabs">
                <el-tabs v-model="activeTab" :before-leave="changePageIndex">
                    <el-tab-pane name="company">
                        <template slot="label">
                            <span>竞品动态</span>
                            <span class="tab_cnt">{{page_data.company_nums> 10000? '10000+':page_data.company_nums}}</span>
                        </template>
                    </el-tab-pane>
                    <el-tab-pane name="word">
                        <template slot="label">
                            <span>关键词动态</span>
                            <span class="tab_cnt">{{page_data.words_nums> 10000? '10000+':page_data.words_nums}}</span>
                        </template>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="search">
                <div class="date_picker">
                    <span>时间筛选：</span>
                    <span class="picker" :class="tabIndex == 0?'active':''" @click="changeTabIndex(0)" style="margin-left: 0">全部</span>
                    <span class="picker" :class="tabIndex == 1?'active':''" @click="changeTabIndex(1)">近1天</span>
                    <span class="picker" :class="tabIndex == 2?'active':''" @click="changeTabIndex(2)">近7天</span>
                    <span class="picker" :class="tabIndex == 3?'active':''" @click="changeTabIndex(3)">近30天</span>
                    <span :style="{'color': wordDatePicker.length == 0?'#333':'#1f81f8'}">自定义：
                    <el-date-picker
                        style="margin-left: 10px; width: 250px"
                        v-model="wordDatePicker"
                        @change="changeDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </span>
                </div>
                <div class="type_picker" v-if="activeTab == 'company'">
                    <span>动态类型：</span>
                    <span class="picker" :class="typeIndex == null?'active':''" style="margin-left: 0" @click="changeType(null)">
                        <span>全部</span>
                    </span>
                    <span class="picker" :class="typeIndex == 1?'active':''" @click="changeType(1)">
                        <span>新增推广关键词</span>
                        <span class="type_cnt">{{page_data.new_words_nums}}</span>
                    </span>
                    <span class="picker" :class="typeIndex == 4?'active':''" @click="changeType(4)">
                        <span>新增推广落地页</span>
                        <span class="type_cnt">{{page_data.new_landpage_nums}}</span>
                    </span>
                    <span class="picker" :class="typeIndex == 2?'active':''" @click="changeType(2)">
                        <span>新增推广平台</span>
                        <span class="type_cnt">{{page_data.new_platform_nums}}</span>
                    </span>
                    <span class="picker" :class="typeIndex == 3?'active':''" @click="changeType(3)">
                        <span>新增推广域名</span>
                        <span class="type_cnt">{{page_data.new_domain_nums}}</span>
                    </span>
                </div>
                <div class="company_picker" v-if="activeTab == 'company'">
                    <span>监测对象：</span>
                    <el-select v-model="selected_company" no-match-text="无匹配数据" filterable @change="changeCompany" size="small" style="width: 250px">
                        <el-option
                            v-for="(item, i) in page_data.company_list"
                            :key="i"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="dynamic_body">
            <div class="list_title">
                <span>共找到<span class="title_cnt">{{listData.real_cnt> 100000? '100000+':listData.real_cnt}}</span>条动态</span>
                <span class="right">
                    <el-checkbox v-model="is_read" @change="changeRead">未读</el-checkbox>
                    <div class="right_btn" @click="is_batch = true" v-if="is_batch == false">
                        <img src="../../../../assets/img/monitor/newest/icon_set.png"/>
                        <span>批量操作</span>
                    </div>
                    <div class="right_btn selected_btn" @click="is_batch = false" v-else>
                        <img src="../../../../assets/img/monitor/newest/icon_set_click.png"/>
                        <span>取消操作</span>
                    </div>
                </span>
            </div>
            <div v-show="is_batch" class="batch">
                <span>已选：{{activeTab == 'company'?companySelection.length:wordSelection.length}}</span>
                <span style="margin-left: 20px" @click="changeReadStatus">
                    <img src="../../../../assets/img/monitor/newest/icon_has.png" height="11" width="16"/>
                    <span style="margin-left: 5px; color: #1f81f8; cursor: pointer">标为已读</span>
                </span>
            </div>
            <div class="list">
                <el-table
                    v-if="activeTab == 'company'"
                    :data="listData.rows"
                    v-loading="tableLoading"
                    @row-click="changeReadStatus"
                    @selection-change="selectionChange"
                    @sort-change="sortChange">
                    <el-table-column
                        v-if="is_batch"
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        show-overflow-tooltip
                        label="监测对象">
                        <template slot-scope="scope">
                            <span class="canClick" :class="scope.row.is_read == '0'?'':'gray'" @click="$C.getCompanyDetails(scope.row.company_name_digest, 3168)">{{scope.row.company_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="80"
                        label="动态类型">
                        <template slot-scope="scope">
                            <div :class="scope.row.is_read == '0'?'':'gray'">
                                <span v-if="scope.row.type == '1'">新词</span>
                                <span v-if="scope.row.type == '2'">新平台</span>
                                <span v-if="scope.row.type == '3'">新域名</span>
                                <span v-if="scope.row.type == '4'">新落地页</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="150"
                        label="推广关键词">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'">{{scope.row.ad_word}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="150"
                        label="创意标题">
                        <template slot-scope="scope">
                            <span class="canClick" :class="scope.row.is_read == '0'?'':'gray'" @click="$C.jump(scope.row.land_url, 3169)">{{scope.row.title}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="100"
                        label="推广平台">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'">{{$C.platformTurn(parseInt(scope.row.platform))}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="100"
                        label="推广域名">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'" class="canClick" @click="$C.jump(scope.row.domain, 3170)">{{scope.row.domain}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable="custom"
                        min-width="150"
                        label="更新时间">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'">{{scope.row.ctime}}</span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <no-data title="暂无监测动态"></no-data>
                    </template>
                </el-table>
                <el-table
                    v-if="activeTab == 'word'"
                    :data="listData.rows"
                    v-loading="tableLoading"
                    @row-click="changeReadStatus"
                    @selection-change="selectionChange"
                    @sort-change="sortChange">
                    <el-table-column
                        v-if="is_batch"
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        min-width="80"
                        show-overflow-tooltip
                        label="监测对象">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'">{{scope.row.ad_word}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="150"
                        label="新增推广公司">
                        <template slot-scope="scope">
                            <span class="canClick" :class="scope.row.is_read == '0'?'':'gray'" @click="$C.getCompanyDetails(scope.row.company_name_digest, 3172)">{{scope.row.company_name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        label="推广文案">
                        <template slot-scope="scope">
                            <span class="canClick" :class="scope.row.is_read == '0'?'':'gray'" @click="$C.jump(scope.row.land_url, 3171)">{{scope.row.title}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="60"
                        label="推广平台">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'">{{$C.platformTurn(parseInt(scope.row.platform))}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="100"
                        label="推广域名">
                        <template slot-scope="scope">
                            <span class="canClick" :class="scope.row.is_read == '0'?'':'gray'" @click="$C.jump(scope.row.domain, 3173)">{{scope.row.domain}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        sortable="custom"
                        min-width="90"
                        label="更新时间">
                        <template slot-scope="scope">
                            <span :class="scope.row.is_read == '0'?'':'gray'">{{scope.row.ctime}}</span>
                        </template>
                    </el-table-column>
                    <template slot="empty">
                        <no-data title="暂无监测动态"></no-data>
                    </template>
                </el-table>
                <div style="position: relative">
                    <span style="color: #999; position: absolute; top: 20px; left: 10px">注：仅保留最近30天的监测动态数据。</span>
                    <Page
                        :config="pageConfig"
                        @pageChange="pageChange"
                        @limitChange="limitChange"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import * as api from '@/api/monitor'
  import common_dialog from "../../../../components/dialogs/common_dialog";
  import export_dialog from "../../../../components/dialogs/export_dialog";
  import PinganAna from 'pingansec-vue-ana'
  export default {
    name: "index",
    components: {
      common_dialog,
      export_dialog,
    },
    data() {
      return {
        page_data: {},
        activeTab: 'company',
        tabIndex: 0,
        typeIndex: null,
        wordDatePicker: [],
        selected_company: '',
        is_read: false,
        pageConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        sort: '',
        listData: {},
        tableLoading: false,
        is_batch: false,
        companySelection: [],
        wordSelection: [],
      }
    },
    created() {
      this.init();
      this.getTableData();
      PinganAna.fire(3160)
    },
    computed: {
      datePicker() {
        let date = {
          b_time: '',
          e_time: ''
        };
        switch (this.tabIndex) {
          case 1:
            date.b_time = this.$C.getBeforeDay(0);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 2:
            date.b_time = this.$C.getBeforeDay(7);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 3:
            date.b_time = this.$C.getBeforeDay(30);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 4:
            date.b_time = this.wordDatePicker[0];
            date.e_time = this.wordDatePicker[1];
            break;
          default:
            date.b_time = ''
            date.e_time = ''
        };
        return date;
      }
    },
    methods: {
      selectionChange(val) {
        val = val.map((el) => {
          el = el.id
          return el
        })
        this.activeTab == 'company'?this.companySelection = val:this.wordSelection = val
      },
      changeRead() {
        this.getTableData()
      },
      changePageIndex(activeName, oldActiveName) {
        if(activeName !== oldActiveName) {
          if(activeName == 'company') {
            PinganAna.fire(3160)
          } else {
            PinganAna.fire(3167)
          }
          this.tabIndex = 0
          this.typeIndex = null
          this.selected_company = ''
          this.is_read = false
          Object.assign(this.$data.pageConfig, this.$options.data().pageConfig);
          this.activeTab = activeName
          this.init();
          this.getTableData()
        }
      },
      changeCompany(val) {
        this.init();
        this.getTableData()
      },
      changeTabIndex(index) {
        if(this.tabIndex != index) {
          this.tabIndex = index;
          this.wordDatePicker = [];
          this.init();
          this.getTableData();
        }
      },
      changeDate() {
        this.tabIndex = 4;
        this.init();
        this.getTableData();
      },
      changeType(index) {
        if(this.typeIndex != index) {
          this.typeIndex = index;
          this.init();
          this.getTableData();
        }
      },
      pageChange(val) {
        this.pageConfig.page = val
        this.getTableData();
      },
      limitChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.limit = val;
        this.getTableData();
      },
      sortChange(column) {
        if(column.order=='ascending') {
          this.sort = 'asc'
        } else {
          this.sort = 'desc'
        }
        this.getTableData();
      },
      changeReadStatus(row) {
        let params = {
            ids: row.id?[row.id]:this.activeTab=='company'?this.companySelection:this.wordSelection
        }
        let p = new Promise((resolve, reject) => {
          api.read_monitor_result_v2(params).then(res => {
            if(res.data.result_code == 0) {
              if(!row.id) {
                this.companySelection = []
                this.wordSelection = []
                this.is_batch = false
              }
              this.getTableData();
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getTableData() {
        this.tableLoading = true
        let params = {
          cat: this.activeTab == 'company'?'1':'2',
          type: this.typeIndex,
          company_name_digest: this.selected_company,
          sort: this.sort,
          read: this.is_read?'0':'',
          b_time: this.datePicker.b_time,
          e_time: this.datePicker.e_time,
          page: this.pageConfig.page,
          limit: this.pageConfig.limit,
        }
        let p = new Promise((resolve, reject) => {
          api.get_monitor_list(params).then(res => {
            if(res.data.result_code == 0) {
              this.listData = res.data.data
              this.pageConfig.total = parseInt(res.data.data.cnt)
              this.tableLoading = false
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      init() {
        let params = {
          cat: this.activeTab == 'company'?'1':'2',
          company_name_digest: this.selected_company,
          b_time: this.datePicker.b_time,
          e_time: this.datePicker.e_time,
        }
        let p = new Promise((resolve, reject) => {
          api.get_monitor_report(params).then(res => {
            if(res.data.result_code == 0) {
              this.page_data = res.data.data
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
.dynamic_main {
    height: 100%;
    overflow-y: scroll;
    .canClick {
        cursor: pointer;
        color: #1f81f8;
    }
    .gray {
        color: #999999;
    }
    .dynamic_header {
        border-radius: 5px;
        border: solid 1px #ebeff2;
        overflow: hidden;
        .title {
            padding: 18px 20px;
            font-size: 16px;
            color: #333333;
            background-color: #fff;
            border-bottom: 1px solid #ebeff2;
        }
        .search {
            padding: 15px 20px;
            background-color: #fff;
            .date_picker {
                font-size: 14px;
                color: #999999;
                .picker {
                    color: #333;
                    padding: 6px 13px;
                    margin: 15px;
                    cursor: pointer;
                    box-sizing: border-box;
                }
                .active {
                    color: #1f81f8;
                    border: 1px solid #1f81f8;
                    border-radius:  3px;
                }
            }
            .type_picker {
                font-size: 14px;
                color: #999999;
                margin: 23px 0;
                .picker {
                    margin-left: 41px;
                    color: #333333;
                    cursor: pointer;
                    .type_cnt {
                        margin-left: 3px;
                        color: #999999;
                    }
                }
                .active {
                    color: #1f81f8;
                    .type_cnt {
                        color: #1f81f8;
                    }
                }
            }
            .company_picker {
                font-size: 14px;
                color: #999999;
            }
        }
    }
    .dynamic_body {
        margin-top: 15px;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        overflow: hidden;
        .batch {
            font-size: 14px;
            padding: 15px;
            background-color: #e8f2fe;
        }
        .list_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 19px;
            font-size: 14px;
            color: #999999;
            border-bottom: 1px solid #ebeff2;
            .title_cnt {
                color: #ef3819;
            }
            .right {
                .right_btn {
                    display: inline-flex;
                    align-items: center;
                    padding: 10px 12px;
                    color: #333333;
                    border-radius: 6px;
                    border: solid 1px #ebeff2;
                    cursor: pointer;
                    margin-left: 16px;
                    img {
                        width: 14px;
                        height: 14px;
                        margin-right: 6px;
                    }
                }
                .selected_btn {
                    color: #1f81f8;
                    border: solid 1px #1f81f8;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.tabs {
    .el-tabs {
        background-color: #fff;
        .el-tabs__header {
            margin: 0;
            .el-tabs__nav-wrap::after {
                height: 1px;
                background-color: #ebeff2;
            }
            .el-tabs__nav {
                height: 52px;
            }
        }
        .el-tabs__item {
            font-size: 16px;
            color: #333333;
            padding: 0 20px !important;
            line-height: 52px;
            .tab_cnt {
                margin-left: 5px;
                font-size: 14px;
                color: #999999;
            }
        }
        .el-tabs__item.is-active {
            font-weight: bold;
            .tab_cnt {
                font-weight: normal;
                color: #ef3819;
            }
        }
    }
}
.list_title {
    .el-checkbox__label {
        padding-left: 5px;
    }
}
.dynamic_main .el-table th.is-leaf {
    background-color: #f8fafc;
    color: #666666;
}
</style>
